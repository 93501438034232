import { useCallback, useEffect, useState } from 'react'
import { loadLabels } from '../utils/labelUtils' // Importando a função loadLabels
import ImageDisplay from './ImageDisplay'
import ClassDisplay from './ClassDisplay'
import { ImageFromBucket } from '../models/ImageFromBucket'
import ImageControls from './ImageControls'
import { ImageAnalyzed } from '../models/ImageAnalyzed'
import { ImageControlsActions } from './ImageControlsActions'
import { ClassContainer } from './ClassContainer'
import { TBucketPostPayload } from '../pages/Home'

type ImageViewerProps = {
  bucket: ImageFromBucket
  getBucketImage: () => void
  postBucketImage: (imageAnalyzed: ImageAnalyzed) => void
  updateBucketImage: (
    imageAnalyzed: ImageAnalyzed,
    payload: TBucketPostPayload[]
  ) => void
}

function ImageViewer({
  bucket,
  getBucketImage,
  postBucketImage,
  updateBucketImage
}: ImageViewerProps) {
  const [rectCoords, setRectCoords] = useState<any>([])
  const [selectedTool, setSelectedTool] = useState<'draw' | 'select'>('select')
  const [elements, setElements] = useState<any[]>([])
  const [selectedElement, setSelectedElement] = useState<any | null>(null)

  useEffect(() => {
    loadLabels(bucket.labelLink).then(setRectCoords)
  }, [bucket])

  const showNext = () => {
    console.log('showNext')
    getBucketImage()
  }

  const updateImage = () => {
    if (!elements.length) {
      console.log('Nenhum elemento encontrado')
      return
    }

    const imageAnalyzed: ImageAnalyzed = {
      BucketName: bucket.bucketName,
      Valid: true
    }
    const payload: TBucketPostPayload[] = elements.map(el => {
      const imageWidth = 1280
      const imageHeight = 720
      const width = Number(((el?.x2 - el?.x1) / imageWidth).toFixed(6))
      const height = Number(((el?.y2 - el?.y1) / imageHeight).toFixed(6))
      const centerX = Number(((1 / imageWidth) * el.x1 + width / 2).toFixed(6))
      const centerY = Number(
        ((1 / imageHeight) * el.y1 + height / 2).toFixed(6)
      )

      return {
        classNumber: el?.classId,
        points: [centerX, centerY, width, height]
      }
    })
    if (
      payload.some(
        item => typeof item.classNumber !== 'number' || item.classNumber < 0
      )
    ) {
      console.log('Preencher classes para todos elementos')
      return
    }
    console.log('Imagem atualizada:', imageAnalyzed)
    updateBucketImage(imageAnalyzed, payload)
  }

  const approveImage = () => {
    const imageAnalyzed: ImageAnalyzed = {
      BucketName: bucket.bucketName,
      Valid: true
    }
    console.log('Imagem aprovada:', imageAnalyzed)
    postBucketImage(imageAnalyzed)
  }

  const removeImage = () => {
    const imageAnalyzed: ImageAnalyzed = {
      BucketName: bucket.bucketName,
      Valid: false
    }
    console.log('Imagem removida:', imageAnalyzed)
    postBucketImage(imageAnalyzed)
  }

  const handleDraw = () => {
    setSelectedTool('draw')
  }

  const handleSelect = () => {
    setSelectedTool('select')
  }

  const handleCloseClass = () => {
    setSelectedElement(null)
  }

  const handleSaveClass = useCallback(
    (option: number | null) => {
      console.log(option)
      if (!selectedElement?.id ?? !option) return
      setElements(prevElements =>
        prevElements.map(element => {
          if (element?.id === selectedElement?.id) {
            return {
              ...element,
              classId: option
            }
          }
          return element
        })
      )

      setSelectedElement(null)
    },
    [selectedElement?.id]
  )

  const handleDeleteClass = useCallback(() => {
    if (!selectedElement?.id) return

    setElements(prevElements =>
      prevElements.filter(element => element.id !== selectedElement.id)
    )
    setSelectedElement(null)
  }, [selectedElement?.id])

  return (
    <div className="image-viewer">
      {bucket ? (
        <div style={{ position: 'relative' }}>
          <ClassContainer
            isOpen={!!selectedElement}
            onClose={handleCloseClass}
            onSave={handleSaveClass}
            onDelete={handleDeleteClass}
            selectedElement={selectedElement}
          />
          <div className="image-container">
            <ImageDisplay
              imagePath={bucket.imageLink}
              rectCoords={rectCoords}
              selectedTool={selectedTool}
              elements={elements}
              setElements={setElements}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
            />
            <ImageControlsActions
              onDraw={handleDraw}
              onSelect={handleSelect}
              selectedTool={selectedTool}
            />
          </div>
          <ImageControls
            showPrevious={() => {
              console.log('showPrevious')
            }}
            showNext={showNext}
            approveImage={approveImage}
            updateImage={updateImage}
            removeImage={removeImage}
          />
          <ClassDisplay rectCoords={rectCoords} />
        </div>
      ) : (
        <p>Nenhuma imagem disponível.</p>
      )}
    </div>
  )
}

export default ImageViewer
