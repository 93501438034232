import { ClassType } from '../models/ClassType';
function ClassDisplay({ rectCoords }: { rectCoords: any }) {
  const currentClassesArray: string[] = []
  const scoreArray: string[] = []

  rectCoords.forEach((coord: any) => {
    const { classId, score } = coord;
    currentClassesArray.push(ClassType(classId));
    scoreArray.push(`${Number(score * 100).toFixed(2)}%`);

  })

  return (
    <div>
      <div>Classes Atuais: {currentClassesArray.join(', ')}</div>
      <div>Score: {scoreArray.join(', ')}</div>
    </div>
  );
}

export default ClassDisplay;
