import { AccountInfo, EventPayload, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './configurations/authConfig'
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';


const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    const { account } = event.payload as EventPayload | AccountInfo | any
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  // <React.StrictMode>
  <App msalInstance={msalInstance} />
  // </React.StrictMode>
  , document.getElementById('root')
);
reportWebVitals();
