import { MsalProvider, MsalProviderProps } from "@azure/msal-react";

import './App.css';
import { Pages } from "./components/Pages";

export function App({ msalInstance }: MsalProviderProps | any) {
  return (
    <>
        <MsalProvider instance={msalInstance}>
          <Pages />
        </MsalProvider>
    </>
  );
}



