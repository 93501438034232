import {
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import rough from 'roughjs'

const generator = rough.generator()

function createElement(x1: number, y1: number, x2: number, y2: number) {
  const id = Date.now()
  const roughElement = generator.rectangle(x1, y1, x2 - x1, y2 - y1, {
    strokeWidth: 2,
    stroke: 'red'
  })
  return { id, x1, y1, x2, y2, roughElement }
}

const nearPoint = (
  x: number,
  y: number,
  x1: number,
  y1: number,
  name: string
) => {
  return Math.abs(x - x1) < 5 && Math.abs(y - y1) < 5 ? name : null
}

const positionWithinElement = (
  x: number,
  y: number,
  element: { x1: any; x2: any; y1: any; y2: any }
) => {
  const { x1, x2, y1, y2 } = element
  const topLeft = nearPoint(x, y, x1, y1, 'tl')
  const topRight = nearPoint(x, y, x2, y1, 'tr')
  const bottomLeft = nearPoint(x, y, x1, y2, 'bl')
  const bottomRight = nearPoint(x, y, x2, y2, 'br')
  const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? 'inside' : null
  return topLeft || topRight || bottomLeft || bottomRight || inside
}

const getElementAtPosition = (x: number, y: number, elements: any[]) => {
  return elements
    .map(element => ({
      ...element,
      position: positionWithinElement(x, y, element)
    }))
    .find(element => element.position !== null)
}

const adjustElementCoordinates = (element: {
  x1: any
  y1: any
  x2: any
  y2: any
}) => {
  const { x1, y1, x2, y2 } = element
  const minX = Math.min(x1, x2)
  const maxX = Math.max(x1, x2)
  const minY = Math.min(y1, y2)
  const maxY = Math.max(y1, y2)
  return { x1: minX, y1: minY, x2: maxX, y2: maxY }
}

const cursorForPosition = (position: any) => {
  switch (position) {
    case 'tl':
    case 'br':
    case 'start':
    case 'end':
      return 'nwse-resize'
    case 'tr':
    case 'bl':
      return 'nesw-resize'
    default:
      return 'move'
  }
}

const resizedCoordinates = (
  clientX: number,
  clientY: number,
  position: any,
  coordinates: { x1: any; y1: any; x2: any; y2: any }
): any | null => {
  const { x1, y1, x2, y2 } = coordinates
  switch (position) {
    case 'tl':
    case 'start':
      return { x1: clientX, y1: clientY, x2, y2 }
    case 'tr':
      return { x1, y1: clientY, x2: clientX, y2 }
    case 'bl':
      return { x1: clientX, y1, x2, y2: clientY }
    case 'br':
    case 'end':
      return { x1, y1, x2: clientX, y2: clientY }
    default:
      return null
  }
}

type TImageDisplay = {
  imagePath: any
  rectCoords: any
  selectedTool: 'draw' | 'select' | null
  elements: any[]
  setElements: React.Dispatch<React.SetStateAction<any[]>>
  selectedElement: any
  setSelectedElement: React.Dispatch<any>
}

function ImageDisplay({
  imagePath,
  rectCoords,
  selectedTool,
  elements,
  setElements,
  selectedElement,
  setSelectedElement
}: TImageDisplay) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const imageRef = useRef(new Image())
  const [action, setAction] = useState('none')

  const drawImage = (ctx: any) => {
    if (ctx && imageRef.current) {
      ctx.drawImage(imageRef.current, 0, 0)
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      console.log(event.key)
      if (event.key === 'Delete' || event.key === 'Backspace') {
        if (selectedElement) {
          setElements(prevElements =>
            prevElements.filter(element => element.id !== selectedElement.id)
          )
          setSelectedElement(null)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedElement, setElements, setSelectedElement])

  useEffect(() => {
    const image = new Image()
    setElements([])

    image.onload = () => {
      const canvas = canvasRef.current
      const ctx = canvas?.getContext('2d')
      if (!canvas || !ctx) return

      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0)

      rectCoords.forEach(
        (coord: {
          centerX: number
          width: number
          centerY: number
          height: number
          classId: number
        }) => {
          const realX =
            coord.centerX * image.width - (coord.width * image.width) / 2
          const realY =
            coord.centerY * image.height - (coord.height * image.height) / 2
          const realWidth = coord.width * image.width
          const realHeight = coord.height * image.height

          if (
            [realX, realY, realWidth, realHeight].some(
              val => val === null || isNaN(val)
            )
          ) {
            console.error('Invalid coordinates:', coord)
            return
          }

          const element = createElement(
            realX,
            realY,
            realX + realWidth,
            realY + realHeight
          )
          setElements(prevState => [
            ...prevState,
            { ...element, classId: coord?.classId ?? null }
          ])
        }
      )
    }
    image.src = imagePath
    imageRef.current = image
  }, [imagePath, rectCoords, setElements])

  useLayoutEffect(() => {
    const canvas = canvasRef.current
    if (canvas && canvas?.width && canvas?.height) {
      const context = canvas.getContext('2d')

      context?.clearRect(0, 0, canvas.width, canvas.height)
      drawImage(context)

      const roughCanvas = rough.canvas(canvas)
      elements.forEach(({ roughElement }) => roughCanvas.draw(roughElement))
    }
  }, [elements])

  const updateElement = useCallback(
    (id: number, x1: number, y1: number, x2: number, y2: number) => {
      const updatedElement = createElement(x1, y1, x2, y2)
      const elementsCopy = [...elements]
      const index = elementsCopy.findIndex(element => element?.id === id)
      if (index < 0) return
      elementsCopy[index] = {
        ...updatedElement,
        id,
        classId: elementsCopy[index]?.classId ?? null
      }
      setElements(elementsCopy)
    },
    [elements, setElements]
  )

  const handleMouseMove = useCallback(
    (event: MouseEvent<HTMLCanvasElement>) => {
      const { clientX: mouseX, clientY: mouseY } = event

      const canvas = canvasRef.current
      if (!canvas) return

      const rect = canvas.getBoundingClientRect()
      const scaleX = canvas.width / rect.width
      const scaleY = canvas.height / rect.height
      const clientX = (mouseX - rect.left) * scaleX
      const clientY = (mouseY - rect.top) * scaleY

      if (!selectedElement) return

      if (selectedTool === 'select') {
        const element = getElementAtPosition(clientX, clientY, elements)
        event.currentTarget.style.cursor = element
          ? cursorForPosition(element.position)
          : 'default'
      }

      if (action === 'drawing') {
        const element = elements?.find(
          element => element?.id === selectedElement?.id
        )

        if (!!element?.id) {
          const { x1, y1 } = element
          updateElement(element?.id, x1, y1, clientX, clientY)
        }
      } else if (action === 'moving') {
        const { id, x1, x2, y1, y2, offsetX, offsetY } = selectedElement
        const width = x2 - x1
        const height = y2 - y1
        const newX1 = clientX - offsetX
        const newY1 = clientY - offsetY
        updateElement(id, newX1, newY1, newX1 + width, newY1 + height)
      } else if (action === 'resizing') {
        const { id, position, ...coordinates } = selectedElement
        const { x1, y1, x2, y2 } = resizedCoordinates(
          clientX,
          clientY,
          position,
          coordinates
        )
        updateElement(id, x1, y1, x2, y2)
      }
    },
    [action, elements, selectedElement, selectedTool, updateElement]
  )

  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLCanvasElement>) => {
      const { clientX: mouseX, clientY: mouseY } = event

      const canvas = canvasRef.current
      if (!canvas) return

      const rect = canvas.getBoundingClientRect()
      const scaleX = canvas.width / rect.width
      const scaleY = canvas.height / rect.height
      const clientX = (mouseX - rect.left) * scaleX
      const clientY = (mouseY - rect.top) * scaleY

      if (selectedTool === 'select') {
        const element = getElementAtPosition(clientX, clientY, elements)
        if (element) {
          const offsetX = clientX - element.x1
          const offsetY = clientY - element.y1
          setSelectedElement({ ...element, offsetX, offsetY })

          if (!selectedElement) return

          if (element.position === 'inside') {
            setAction('moving')
          } else {
            setAction('resizing')
          }
        }
      } else {
        const element = createElement(clientX, clientY, clientX, clientY)
        setElements(prevState => [...prevState, element])
        setSelectedElement(element)

        setAction('drawing')
      }
    },
    [elements, selectedElement, selectedTool, setElements, setSelectedElement]
  )

  const handleMouseUp = useCallback(() => {
    if (!selectedElement) return

    const element = elements.find(
      element => element?.id === selectedElement?.id
    )
    if (!element) return

    if (action === 'drawing' || action === 'resizing') {
      const { x1, y1, x2, y2 } = adjustElementCoordinates(element)
      updateElement(element?.id, x1, y1, x2, y2)
    }
    setAction('none')
    // setSelectedElement(null)
  }, [action, elements, selectedElement, updateElement])

  return (
    <canvas
      ref={canvasRef}
      id="canvas"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{
        cursor: selectedTool === 'draw' ? 'crosshair' : 'auto',
        width: '90vw',
        height: '80vh',
        backgroundColor: '#ccc'
      }}
    />
  )
}

export default ImageDisplay
