import { SetStateAction, useCallback, useEffect, useState } from 'react'
import { useAPIToken } from '../services/hooks/usoAuthenticationResult'
import { ImageFromBucket } from '../models/ImageFromBucket'
import { api } from '../services/api'
import { ImageAnalyzed } from '../models/ImageAnalyzed'
import ImageViewer from '../components/ImageViewer'

export type TBucketPostPayload = {
  classNumber: number
  points: [number, number, number, number]
}

export function Home() {
  const { data: token } = useAPIToken()
  const [image, setImage] = useState<ImageFromBucket>()
  const [loading, setLoading] = useState(true)
  const getBucketImage = useCallback(() => {
    setLoading(true)
    api
      .get<ImageFromBucket>('/Bucket', {
        headers: {
          Authorization: `Bearer ${token}`,
          AccessControlAllowOrigin: '*',
          AccessControlAllowHeaders:
            'Origin, X-Requested-With, Content-Type, Accept'
        }
      })
      .then(
        (response: { data: SetStateAction<ImageFromBucket | undefined> }) => {
          response.data && setImage(response.data)
          setLoading(false)
        }
      )
      .catch((error: any) => {
        console.error('Erro ao carregar imagens:', error)
        setLoading(false)
      })
  }, [token])

  const updateBucketImage = useCallback(
    (imageAnalyzed: ImageAnalyzed, payload: TBucketPostPayload[]) => {
      console.log({ imageAnalyzed, payload })
      setLoading(true)
      api
        .post<string>(
          `/bucket/update-label/${imageAnalyzed.BucketName}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              AccessControlAllowOrigin: '*',
              AccessControlAllowHeaders:
                'Origin, X-Requested-With, Content-Type, Accept'
            }
          }
        )
        .then((response: { data: any }) => {
          response.data && console.log(response.data)
          setLoading(false)
          getBucketImage()
        })
        .catch((error: any) => {
          console.error('Erro ao carregar imagens:', error)
          setLoading(false)
        })
    },
    [getBucketImage, token]
  )

  const postBucketImage = useCallback(
    (imageAnalyzed: ImageAnalyzed) => {
      setLoading(true)
      api
        .post<string>('/Bucket', imageAnalyzed, {
          headers: {
            Authorization: `Bearer ${token}`,
            AccessControlAllowOrigin: '*',
            AccessControlAllowHeaders:
              'Origin, X-Requested-With, Content-Type, Accept'
          }
        })
        .then((response: { data: any }) => {
          response.data && console.log(response.data)
          setLoading(false)
          getBucketImage()
        })
        .catch((error: any) => {
          console.error('Erro ao carregar imagens:', error)
          setLoading(false)
        })
    },
    [getBucketImage, token]
  )

  useEffect(() => {
    getBucketImage()
  }, [getBucketImage])

  return (
    <div className="App">
      {loading ? (
        <p>Carregando imagens...</p>
      ) : image ? (
        <ImageViewer
          bucket={image}
          getBucketImage={getBucketImage}
          postBucketImage={postBucketImage}
          updateBucketImage={updateBucketImage}
        />
      ) : (
        <p>Nenhuma imagem disponível.</p>
      )}
    </div>
  )
}
