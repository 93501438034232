import ReactSelect from 'react-select'
import './ClassContainer.css'
import { useEffect, useState } from 'react'
import { CloseCircle } from 'iconsax-react'

type TClassContainer = {
  isOpen?: boolean
  onDelete: () => void
  onSave: (option: number | null) => void
  onClose: () => void
  selectedElement: any
}

type TOptions = {
  label: string
  value: number
}
const options: TOptions[] = [
  { label: 'CAPACETE', value: 0 },
  { label: 'CINTO', value: 1 },
  { label: 'CELULAR', value: 2 },
  { label: 'CIGARRO', value: 3 },
  { label: 'LUVA', value: 4 },
  { label: 'CONE', value: 5 },
  { label: 'UNIFORME', value: 6 },
  { label: 'SONO', value: 7 }
]

export function ClassContainer({
  isOpen = true,
  onDelete,
  onSave,
  onClose,
  selectedElement
}: TClassContainer) {
  const [option, setOption] = useState<TOptions | null>(null)

  useEffect(() => {
    setOption(
      options.find(opt => opt.value === selectedElement?.classId) || null
    )
  }, [selectedElement])

  if (!isOpen) return null

  return (
    <div
      className="class-container"
      style={{
        position: 'absolute'
      }}
    >
      <button onClick={onClose} className="class-close-btn">
        <CloseCircle size={26} />
      </button>
      <label htmlFor="class" style={{ fontSize: '24px' }}>
        Classe
      </label>
      <ReactSelect
        className="class-select"
        inputId="class"
        placeholder="Selecione a classe"
        value={option}
        onChange={value => setOption(value)}
        options={options}
        required
      />
      <div className="class-buttons">
        <button style={{ backgroundColor: '#d82e2e' }} onClick={onDelete}>
          Deletar
        </button>
        <button onClick={() => onSave(option?.value ?? null)}>Salvar</button>
      </div>
    </div>
  )
}
