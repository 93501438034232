import { useQuery } from "react-query";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";



export async function getToken(instance: IPublicClientApplication, account: AccountInfo): Promise<string> {

    return (await instance.acquireTokenSilent({
            scopes: [process.env.REACT_APP_API_SCOPE!],
            account
        })).accessToken
}

export function useAPIToken() {
    const { instance, accounts } = useMsal();
    return useQuery("api-token", () => getToken(instance, accounts[0]), { staleTime: 1000 * 60 * 60 }); //60 min
}

