export const ClassType = (classId: number) => {

    switch (classId) {
        case 0: return 'CAPACETE';
        case 1: return 'CINTO';
        case 2: return 'CELULAR';
        case 3: return 'CIGARRO';
        case 4: return 'LUVA';
        case 5: return 'CONE';
        case 6: return 'UNIFORME';
        case 8: return 'SONO';
        default: return 'Unknown';
    }
}

