import { FormatSquare, Maximize4 } from 'iconsax-react'
import './ImageControlsActions.css'

type TImageControlsActions = {
  onDraw: () => void
  onSelect: () => void
  selectedTool: 'draw' | 'select'
}

export function ImageControlsActions({
  onDraw,
  onSelect,
  selectedTool
}: TImageControlsActions) {
  return (
    <div className="actions-container">
      <button
        className={
          selectedTool === 'draw'
            ? 'actions-container-button actions-container-button-active'
            : 'actions-container-button'
        }
        onClick={onDraw}
      >
        <FormatSquare />
      </button>
      <button
        className={
          selectedTool === 'select'
            ? 'actions-container-button actions-container-button-active'
            : 'actions-container-button'
        }
        onClick={onSelect}
      >
        <Maximize4 />
      </button>
    </div>
  )
}
