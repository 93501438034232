import React from 'react'

function ImageControls({
  showNext,
  approveImage,
  removeImage,
  updateImage,
  imageName
}: any) {
  return (
    <div>
      <button onClick={showNext}>Próxima</button>
      <button onClick={approveImage}>Aprovar</button>
      <button onClick={updateImage}>Update</button>
      <button onClick={removeImage}>Remover</button>
    </div>
  )
}

export default ImageControls
