export async function loadLabels(label:string) {
  try {
    const response = await fetch(label);
    if (!response.ok) {
      throw new Error('Falha ao carregar o arquivo de rótulos');
    }
    const text = await response.text();
    const lines = text.split('\n').filter(line => line.trim() !== '');
    return lines.map(line => {
      const parts = line.split(' ').map(Number);
      if (parts.length === 6) {
        const [classId, x, y, width, height,score] = parts;
        return { classId, centerX: x, centerY: y, width, height,score };
      }
      return null;
    }).filter(label => label !== null);
  } catch (error) {
    console.error('Erro ao carregar arquivo de rótulos:', error);
    return [];
  }
}
